import './import-jq';
import { initializeHeader } from './header';

$(() => {
    const env = $('meta[name="env"]').attr('content') || "";

    if (['production', 'staging'].includes(env)) {
        Sentry.init({
            environment: env,
        });
    }
    initializeHeader();
})