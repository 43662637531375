import './import-jq';
import {SearchManager} from './search';


let searchManager : SearchManager | undefined = undefined

export const initializeHeader = () => {
    let themeVal = localStorage.getItem("ngtheme");
    if (!themeVal) {
        themeVal = "ngblueorangelight";
        localStorage.setItem("ngtheme", themeVal);
    }
    $("label.theme-switcher").toggleClass("swap-active", themeVal == "ngblueorangedark");
    $('html').attr("data-theme", themeVal)

    searchManager = new SearchManager($("#headersearchbox"), (results) => {
        const baseUrl = $("#baseurl").data("baseurl").replace("/placeholder", "")
        $("#searchresults ul").empty();
        if (results.tools.length > 0) {
            results.tools.forEach((tool) => {
                $("#searchresults ul").append(`<li><a href=${baseUrl + tool.refname}>${tool.title}</a></li>`);
            });
        } else {
            $("#searchresults ul").append("<li>No results found</li>");
        }
        $("#searchresults").addClass("dropdown-open");
    }, (error) => {
        $("#searchresults ul").empty();
        $("#searchresults ul").append("<li>Error fetching search data</li>");
    });

    $("#searchresults").on('mouseleave', function() {
        $("#searchresults").removeClass("dropdown-open");
    })
}


// Menu logic
// Close menu when
// 1) another menu is opened
// 2) mouse leaves menu
// 3) Menu item is clicked - this is nav
//

$("details").on('click', function() {
    $("details").not(this).removeAttr('open');
})

$("details ul").on('mouseleave', function() {
    this.closest("details")?.removeAttribute('open');
})

// $("details a").on('click', function() {
//     console.log("select");
//     this.closest("details")?.removeAttribute('open');
// })



// Theme selector - must use JS to do the swap because only some browsers 
// support the CSS-only swap

$("label.theme-switcher").on('click', function(event) {
    $(this).toggleClass("swap-active");
    $('html').attr("data-theme", (i, attr) => {
        const val = attr == "ngblueorangelight" ? "ngblueorangedark" : "ngblueorangelight";
        localStorage.setItem("ngtheme", val);
        return val;
    })
})


// Mobile menu - open/close
$("#mobile-menu-button").on('click', function() {
    $("#mobile-menu").toggleClass("hidden");
    $("#mobile-menu-block-open").toggleClass("block");
    $("#mobile-menu-block-open").toggleClass("hidden");
    $("#mobile-menu-block-closed").toggleClass("hidden");
    $("#mobile-menu-block-closed").toggleClass("block");
    $("#nav").toggleClass("open");
})