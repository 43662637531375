type ToolSearchResult = {
    refname: string;
    title: string;
    description: string;
}

type ToolSearchResults = Array<ToolSearchResult>;

type SearchResults = {
    tools: ToolSearchResults;
}

type SearchResultsCallback = (results: SearchResults) => void;
type SearchErrorCallback = (error: Error) => void;

class SearchManager {
    searchBox: JQuery<HTMLElement> | undefined = undefined;
    searchResultsCallback: SearchResultsCallback | undefined = undefined;
    searchErrorCallback: SearchErrorCallback | undefined = undefined;

    constructor(searchBox: JQuery<HTMLElement>, searchResultsCallback: SearchResultsCallback, searchErrorCallback: SearchErrorCallback) {
        this.searchBox = searchBox;
        this.searchResultsCallback = searchResultsCallback;
        this.searchErrorCallback = searchErrorCallback;
        this.searchBox.on('input', this.search);
    }

    async fetchData(searchTerms: string): Promise<void> {
        try {
            const response = await $.ajax({
                url: '/search/',
                method: 'GET',
                data: { terms: searchTerms }
            });

            if (this.searchResultsCallback) {
                this.searchResultsCallback(response);
            }
        } catch (error) {
            const e = new Error(`Error fetching search data: ${error}`);
            if (this.searchErrorCallback) {
                this.searchErrorCallback(e);
            }
        }
    }

    // Debounce function
    debounce(func: Function, timeout = 300) {
        let timer: number;
        return (...args: any) => {
            clearTimeout(timer);
            timer = window.setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    // Event listener with debounced handler
    search = this.debounce((event: JQuery.TriggeredEvent) => {
        const value = $(event.target).val() as string;
        if (value.trim().length > 0) {
            this.fetchData(value.trim());
        } else {
            if (this.searchResultsCallback) {
                this.searchResultsCallback({ tools: [] });
            }
        }
    }, 500);  // Adjust debounce time as necessary
}

export { SearchManager };